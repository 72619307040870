import { render, staticRenderFns } from "./BannerGallery.vue?vue&type=template&id=2a07f7a6&scoped=true&"
import script from "./BannerGallery.vue?vue&type=script&lang=js&"
export * from "./BannerGallery.vue?vue&type=script&lang=js&"
import style0 from "./BannerGallery.vue?vue&type=style&index=0&id=2a07f7a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a07f7a6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VCard,VCol,VContainer,VDivider,VFadeTransition,VHover,VIcon,VImg,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VNavigationDrawer,VOverlay,VProgressCircular,VRow,VSnackbar,VTab,VTabItem,VTabs,VTooltip})
